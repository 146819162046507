@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

p {
  margin: 0px;
}
.PinSection .rightLogo img {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 300px;
  height: auto;
}
.PinSection {
  height: 100vh;
  position: relative;
}
.PinSection .formWraper {
  font-family: "Montserrat", sans-serif;
  border: 1px solid rgba(207, 52, 140, 1);
  width: 100%;
  padding: 25px 30px;
  border-radius: 10px;
  background-color: #fff;
}
.PinSection .formWraper .healthText p {
  font-size: 16px;
  margin-bottom: 0px;
  color: rgba(68, 49, 114, 1);
  text-align: center;
}
.PinSection .formWraper .healthText span {
  font-weight: 700;
  text-emphasis: center;
}
.inputnumber {
  margin-top: 10px;
  line-height: 18px;
  font-size: 13px !important;
}
.otpinput-group {
  display: flex;
  column-gap: 15px;
  margin: 25px 0px;
}
.otpinput-group .input-group-prepend .input-group-text {
  border-radius: 12px;
  border: 1px solid rgba(207, 52, 140, 1);
  background-color: #fff;
  padding: 10px;
}
.otpinput-group .telinput {
  border: 1px solid rgba(207, 52, 140, 1);
  border-radius: 12px;
  padding-left: 15px;
  outline: none;
  width: 100%;
}
.otpinput-group .telinput::placeholder {
  color: rgba(43, 43, 43, 1);
  font-size: 16px;
}
.PinSection .formWraper .sendPin button {
  border-radius: 57px;
  background-color: rgba(207, 52, 140, 1);
  text-align: center;
  color: #fff;
  outline: none;
  border: none;
  width: 100%;
  font-weight: 600;
  padding: 9px 0;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
.PinSection .formWraper .resend {
}
.PinSection .formWraper .resend p {
  color: rgba(43, 43, 43, 1);
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 14px;
}
.PinSection .formWraper .resend span {
  color: rgba(207, 52, 140, 1);
  font-weight: 600;
  /* font-size: 18px; */
  cursor: pointer;
}
.PinSection .formWraper .resend .note {
  font-size: 12px;
  /* font-weight: 400; */
  color: rgba(125, 125, 125, 1);
  margin: 0px;
  text-align: center;
  margin-top: 12px;
}
.faimlyImg img {
  width: 500px;
}
.bottomImg img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 230px;
}
.faimlybg {
  height: 100vh;
  display: flex;
  align-items: center;
}
.absoluteImg {
  position: absolute;
  z-index: -1;
  width: 100%;
  bottom: 0;
}
.errowMsg {
  color: red;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 5px;
  padding: 0 35px;
}
.otp_input {
  display: flex;
  column-gap: 10px;
  margin: 25px 0px;
  justify-content: space-between;
}
.otp_input input {
  width: 47px;
  border: 1px solid rgba(207, 52, 140, 1);
  border-radius: 12px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}
.otp_input input::-webkit-outer-spin-button,
.otp_input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mobileimg {
  display: none;
}
.show-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.show-count svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.show-count h1 {
  color: rgba(43, 43, 43, 1);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.m-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.show-count span {
  width: 46px;
  cursor: unset !important;
  font-size: 14px !important;
}


.lp-content {
  position: fixed;
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff;
  
}

.lp-content .c-div {
  background-image: url(../Assets/landing-popup-bg.webp);
  background-size: cover;
  min-height: 300px;
  max-width: 300px;
  padding: 35px 15px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: black !important;
  border-radius: 4px;
  
}

.lp-content .title {
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
}

.lp-content .title span {
  color:rgba(207, 52, 140, 1);
  font-weight: 600;
}

.lp-content button {
  width: 100%;
  float: left;
  background-color: rgba(207, 52, 140, 1);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.lp-content button:hover {
  background-color: rgba(207, 52, 140, 1);
  color: #fff;
  transition: all .2s ease-in-out;
}

@media (min-width: 360px) and (max-width: 767px) {
  .mobileimg {
    display: block;
    padding-top: 60px;
    /* padding-top: 80px; */
  }
  .faimlybg {
    background-image: none;
    height: unset;
  }
  .absoluteImg {
    display: none;
  }
  .PinSection .rightLogo img {
    max-width: 160px;
  }
}
