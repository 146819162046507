@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
body {
  font-family: "Montserrat", sans-serif !important;
}
img {
  width: 100%;
  height: auto;
}
.mediq-wraper {
  background-color: #443173;
  height: 100vh;
}
.main-content {
  width: 100%;
  max-width: 400px;
  padding: 0px 15px 40px;
  margin: 0 auto;
  position: relative;
}
.logo {
  width: 100%;
  position: relative;
  z-index: 10;
}
.logo img {
  width: 100px;
  margin: 0px auto;
  display: block;
  padding: 20px 0px 10px;
  text-align: center;
}
.mediqtext {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  text-transform: capitalize;
}
.mediqhead {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
}
.mediqshottext {
  color: #fbd8eb;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.mainimg img {
  width: 100%;
  margin-top: 30px;
}
.linesimg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.main-content .logo {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .mediqhead {
    font-size: 24px;
  }
  .mediqtext {
    font-size: 16px;
  }
  .mediqshottext {
    font-size: 12px;
  }
 
}
