.awareness-page {
    background-color: #42346f;
    min-height: 100vh;
    padding-bottom: 10px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .awareness-page .image {
    max-width: 375px;
    margin: auto;
  }
  
  .awareness-page .image img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 440px){
    .awareness-page .image {
      max-width: 100%;
    }
  }
  